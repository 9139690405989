* {
  margin: 0;
  padding: 0;
}

html,
body {
  height: 100%;
}

.underline {
  text-decoration: underline;
}
#root {
  height: 100%;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
