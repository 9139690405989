.phoneConfirm {
  display: grid;
  place-items: center;
  height: 100%;
  /* width: 100%; */
  /*  */
  padding: 25px;
}

.phoneConfirm h3 {
  max-width: 250px;
  text-align: center;
  margin: 0 auto;
}
/*  */

.phoneConfirm .ant-card-bordered {
  -webkit-box-shadow: 4px 4px 16px -8px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 4px 4px 16px -8px rgba(34, 60, 80, 0.2);
  box-shadow: 4px 4px 16px -8px rgba(34, 60, 80, 0.2);
}

.background-phone-register {
  background-color: #e7eded;
}

/* @media (max-width: 700px) {
  .phoneConfirmModal {
    width: 60% !important;
  }
}
*/
@media (max-width: 580px) {
  .phoneConfirmModal {
    margin: 0;
    width: 100% !important;
    max-width: 100% !important;
  }
}
