#components-layout-demo-responsive .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.2);
}

.site-layout-sub-header-background {
  background: #fff;
}

.site-layout-background {
  background: #fff;
}

.logo {
  display: flex;
  justify-content: center;
}

.cabinet_status {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.ant-layout {
  background: #f8f8f8 !important;
}
