.checkOrder_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
}

.checkOrder_wrapper .ant-input-group-wrapper {
  width: 50%;
}

.checkOrder_wrapper .ant-input-group-wrapper {
  margin-bottom: 50px;
}

.checkOrder_wrapper .ant-input-number {
  width: 100%;
}

.searchResult__wrapper {
  width: 50%;
}

@media (max-width: 700px) {
  .checkOrder_wrapper .ant-input-group-wrapper {
    width: 80%;
  }

  .searchResult__wrapper {
    width: 80%;
  }
  .checkOrder_wrapper {
    justify-content: flex-start;
  }
}
