.order_details {
  display: flex;
  column-gap: 40px;
  width: 100%;
  height: 100%;
}

.qrCode {
  display: flex;
  column-gap: 50px;
  align-items: center;
  justify-content: center;
}

.timeline_result {
  margin-top: 30px;
  width: 50%;
}
