.scanMode {
  animation: col 1s infinite;
}

@keyframes col {
  0% {
    background-color: #e4e3e3;
  }
  50% {
    background-color: #e8e8e8;
  }
  100% {
    background-color: #fff;
  }
}
